
import logo from './logo.png'

const Header = () => {
    return (
        <div className='image'>
            <img src={logo} alt='logo' />
            <h1>Video Message</h1>
        </div>
    )
}

export default Header

